import { createStore as reduxCreateStore } from "redux"

const reducer = (state, action) => {
  if (action.type === `LOGIN_CHECKING`) {
    return Object.assign({}, state, {
      loading: true,
    })
  }

  if (action.type === `LOGIN_SUCCESS`) {
    return Object.assign({}, state, {
      loading: false,
      user: action.payload.user,
    })
  }

  if (action.type === `LOGIN_FAILED`) {
    return Object.assign({}, state, {
      loading: false,
      user: {},
    })
  }

  return state
}

const initialState = { isLoggedIn: false, user: {}, loading: true }

const createStore = () => reduxCreateStore(reducer, initialState)
export default createStore
