import { Provider } from "react-redux"
import createStore from "./src/state/createStore"

const React = require("react")
const Keycloak = require("keycloak-js")
const { KeycloakProvider } = require("@react-keycloak/web")

const keycloak = new Keycloak({
  realm: process.env.REALM || "UniHeart",
  url: process.env.AUTH_URL || "https://keycloak.jiwai.win/auth",
  clientId: process.env.AUTH_CLIENT_ID || "UniHeart-Client-Local",
})

const Loading = () => <div>getting ready...</div>

const wrapRootElementInner = ({ element }) => {
  const store = createStore()

  return (
    <Provider store={store}>
      <KeycloakProvider
        store={store}
        keycloak={keycloak}
        initConfig={{
          promiseType: "native",
          onLoad: "check-sso",
          silentCheckSsoRedirectUri:
            window.location.origin + "/silent-check-sso.xhtml",
        }}
        LoadingComponent={<Loading />}
      >
        {element}
      </KeycloakProvider>
    </Provider>
  )
}

export const wrapRootElement = wrapRootElementInner
